import * as React from "react";
import { graphql } from "gatsby";

import { Layout, MainLogo } from "../components/layout";
import Seo from "../components/seo";
import { Member } from "../components/member";

const leaderList = [
  "annedorko",
  "rinnsohma",
  "unkyra",
  "jennrogue",
  "resonanthearts",
  "triggatones",
  "glimjii",
  "tapgirl301"
];

const MembersPage = ({ data }) => {
  var leaders = []
  var members = []
  data.twitchTeam.members.forEach(member => {
    var is_leader = leaderList.includes(member.display_name.toLowerCase());
    if (is_leader) {
      leaders.push(member)
    } else {
      members.push(member)
    }
  });

  console.log(data.twitchTeam);

  return (
    <Layout>
      <Seo title="Plant Army: Twitch Stream Team" />
      <div className="pt-20 pb-8 lg:pt-32 lg:pb-24 container max-w-3xl	mx-auto">
        <div className="flex flex-wrap lg:flex-nowrap justify-center justify-items-center content-center items-center">
          <div className="lg:flex-none">
            <MainLogo />
          </div>
          <div className="text-center lg:text-left lg:flex-1 lg:pl-12 p-6 max-w-lg">
            <h2 className="font-serif text-3xl lg:text-4xl font-semibold leading-none lg:leading-none pb-4">
              Plant Army Members
            </h2>
            <p className="text-sm lg:text-base">
              Founded {data.twitchTeam.created}
            </p>
          </div>
        </div>
      </div>
      <div className="container max-w-3xl	mx-auto">
        <div className="flex flex-wrap px-4 lg:px-0 justify-between">
          {leaders.map(member => {
            return (
              <Member
                key={member.display_name}
                member={member}
                leaders={leaderList}
              />
            );
          })}
          {members.map(member => {
            return (
              <Member
                key={member.display_name}
                member={member}
                leaders={leaderList}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    twitchTeam {
      created(formatString: "MMMM Do, YYYY")
      members {
        display_name
        profile_image_url
        broadcaster_type
        description
      }
    }
  }
`;

export default MembersPage;

import * as React from "react";
import sanitizeHtml from "sanitize-html";
import {StaticImage} from "gatsby-plugin-image";

function Username(props) {
  const member = props.member;
  return <>{member.display_name}</>;
}
function PartnerIcon(props) {
  const member = props.member;
  return (
    <>
      {member.broadcaster_type === "partner" ? (
        <div>
          <StaticImage
            src="../images/partner.svg"
            alt="Twitch Partner Icon"
            width={25}
            height={25}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
function LeaderTag(props) {
  const is_leader = props.isLeader;
  const memberStyles = props.memberStyles;
  if (is_leader) {
    return <p className={memberStyles}>Team Leader</p>;
  }
  return null;
}
function UserDescription(props) {
  const member = props.member;
  const descriptionArray = member.description.split("\n");
  var descriptionHTML = null;
  if (descriptionArray.length > 1) {
    var dList = [];
    descriptionArray.forEach(item => {
      const sanitize = sanitizeHtml(item);
      dList.push('<p class="pb-2">' + sanitize + "</p>");
      descriptionHTML = dList.join("");
    });
  } else {
    const sanitize = sanitizeHtml(props.member.description);
    descriptionHTML = '<p class="pb-2">' + sanitize + "</p>";
  }
  return (
    <div
      className="break-words text-xs p-4"
      dangerouslySetInnerHTML={{
        __html: descriptionHTML
      }}
    ></div>
  );
}

export function Member(props) {
  const member = props.member;
  const username = member.display_name.toLowerCase();
  const link = "https://www.twitch.tv/" + username;
  const is_leader = props.leaders.includes(username);
  const bg = is_leader ? "bg-pink-900" : "bg-indigo-900";
  const bgColor =
    "text-xs font-bold flex flex-nowrap justify-between py-2 px-4 text-white content-center break-words " +
    bg;
  const memberStyles =
    "text-xs text-[.65em] font-normal font-sans px-4 py-1 text-pink-300 bg-pink-800";
  return (
    <a
      key={username}
      href={link}
      target="_blank"
      rel="noreferrer"
      className="w-full sm:w-[49%] lg:w-[23%] bg-white rounded drop-shadow-md mb-4"
    >
      <img
        src={member.profile_image_url}
        className="rounded-t w-full"
        alt={member.display_name}
      />
      <div className="break-words">
        <h3 className={bgColor}>
          <Username member={member} />
          <PartnerIcon member={member} />
        </h3>
        <LeaderTag isLeader={is_leader} memberStyles={memberStyles} />
        <UserDescription member={member} />
      </div>
    </a>
  );
}
